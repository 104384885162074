<script setup>
defineProps({
  buttonText: {
    type: String,
    required: true,
  },
  disabled: {
    default: false,
    type: Boolean,
  },
  loading: {
    default: false,
    type: Boolean,
  },
  type: {
    default: 'button',
    type: String,
  },
})

const emit = defineEmits(['buttonClicked'])

function buttonClicked() {
  emit('buttonClicked')
}
</script>

<template>
  <div class="button-container-2">
    <PrimeButton
      v-if="!disabled && !loading"
      class="mas flex-center flex h-full w-full bg-white px-0"
      outlined
      @click="buttonClicked"
    >
      {{ buttonText }}
    </PrimeButton>
    <PrimeButton
      :disabled="disabled"
      :loading="loading"
      class="btn-fade flex-center flex h-full w-full"
      :type="type"
      @click="buttonClicked"
    >
      {{ buttonText }}
    </PrimeButton>
  </div>
</template>

<style lang="scss">
@mixin button($bcolor, $url, $x1, $y1, $border, $color) {
 background: $bcolor;
 -webkit-mask: url($url);
 mask: url($url);
 -webkit-mask-size: $x1 $y1;
 mask-size: $x1 $y1;
 border: $border;
 color: $color;
}

.button-container-2 {
 position: relative;

 .btn-fade {
  width: 100%;
  height: 100%;

  @include button(#DC7818, "@/assets/images/button-sprite.png", 3000%, 100%, none, white);

  cursor: pointer;
  -webkit-animation: ani2 0.7s steps(29) forwards;
  animation: ani2 0.7s steps(29) forwards;

  &:hover {
   -webkit-animation: ani 0.7s steps(29) forwards;
   animation: ani 0.7s steps(29) forwards;
  }
 }
}

.mas {
  position: absolute;
  color: #DC7818;
  text-align: center;
  width: 100%;
  overflow: hidden;
  font-weight: bold;
}

@-webkit-keyframes ani {
 from {
  -webkit-mask-position: 0 0;
  mask-position: 0 0;
 }

 to {
  -webkit-mask-position: 100% 0;
  mask-position: 100% 0;
 }
}

@keyframes ani {
 from {
  -webkit-mask-position: 0 0;
  mask-position: 0 0;
 }

 to {
  -webkit-mask-position: 100% 0;
  mask-position: 100% 0;
 }
}

@-webkit-keyframes ani2 {
 from {
  -webkit-mask-position: 100% 0;
  mask-position: 100% 0;
 }

 to {
  -webkit-mask-position: 0 0;
  mask-position: 0 0;
 }
}

@keyframes ani2 {
 from {
  -webkit-mask-position: 100% 0;
  mask-position: 100% 0;
 }

 to {
  -webkit-mask-position: 0 0;
  mask-position: 0 0;
 }
}
</style>
